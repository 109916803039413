import { Category } from "~/models/category.model";

export default definePayloadPlugin(() => {
  // Identifies the Category class
  // And then gets the data (server-side)
  definePayloadReducer("Category", (data) => {
    if (data instanceof Category) {
      return {
        id: data.id,
        code: data.code,
        name: data.name,
        slug: data.slug,
        labels: data.labels,
        assets: data.assets,
        productsCount: data.productsCount,
        compilation: data.compilation,
        promotion: data.promotion,
        parent: data.parent,
      };
    }
  });

  // "Revive" the payload
  // Take the data -> create a new class instance
  definePayloadReviver("Category", (data) => {
    return new Category(data, data.parent);
  });
});
